import React from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from './../../config/page-settings.js';
import SidebarProfile from './sidebar-profile.jsx';
import SidebarNav from './sidebar-nav.jsx';

class Sidebar extends React.Component {
  static contextType = PageSettings;

  render() {
    return (
      <PageSettings.Consumer>
        {({ toggleSidebarMinify, toggleMobileSidebar, pageSidebarTransparent }) => (
          <React.Fragment>
            <div id="sidebar" className={'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '') + ' font-lato-normal-500'}>
              <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                {!this.context.pageSidebarSearch && <SidebarProfile />}
                <SidebarNav />
                <Link to="/" className="sidebar-minify-btn" onClick={toggleSidebarMinify}>
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <div style={{ flexGrow: 1 }}></div>
                <a
                  href={'https://sirioapp.com/index.php/acerca-de-sirio/'}
                  target="_blank"
                  style={{
                    padding: '7px 20px 20px 20px',
                    marginLeft: '1rem',
                    lineHeight: '20px',
                    color: 'rgba(255, 255, 255, 0.6)',
                    display: 'block',
                    textDecoration: 'none',
                    marginTop: 'auto',
                    alignSelf: 'flex-end',
                    position: 'absolute',
                    bottom: '0',
                  }}
                  rel="noreferrer">
                  Acerca de Sirio
                </a>
              </PerfectScrollbar>
            </div>
            <div className="sidebar-bg"></div>
            <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar}></div>
          </React.Fragment>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Sidebar;
