import React, { useState, useEffect, createRef } from 'react';
import CardContainer from '../../../components/card/card-container.jsx';
import Api from '../../../api/global.service';
import { subscribe, unsubscribe } from '../../../components/events';
import './entregas-x-productor.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
import { Link } from 'react-router-dom';

import ModalFirma from './modal-firma.jsx';
import { publish } from '../../../components/events.js';
import { getIdCatCreditoPrecosecha } from '../../../components/content/coop-config.jsx';
import formatMoneyNumber from '../../../utils/formatNumber.js';

const EntregasXProductorFirma = ({ metodosPago, data, cuenta, importeBruto, pesoNeto }) => {
  const [blocking, setBlocking] = useState(false);
  const dialog = createRef();
  const [imageURL, setImageURL] = useState(null);
  const [metodoPago, setMetodoPago] = useState();

  const handleFirma = event => {
    setImageURL(event.detail);
  };

  useEffect(() => {
    let id_metodo_pago = metodosPago.filter(metodo => [Number(data.id_metodo_pago)].includes(metodo.value));
    if (data.firma_image) setImageURL(data.firma_image);
    if (id_metodo_pago.length === 1) {
      setMetodoPago(id_metodo_pago[0].label);
    }
    subscribe('Firma::onSave', e => handleFirma(e));
    return () => {
      unsubscribe('Firma::onSave');
    };
  }, []);

  const crear = formData => {
    Api.createMultiPart('admin/acopios/entregas', formData)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito la entrega`,
        }).then(() => {
            setBlocking(false);
            window.location.href = `/admin/acopio/entregas_por_productor/list`;
        })
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        }).then((res) => {
          if (res.isConfirmed) {
            setBlocking(false);
          }
        });
      });
  };

  const enviarDatos = () => {
    setBlocking(true);
    const formData = new FormData();
    for (var key in data) {
      let v = data[key];
      if (v instanceof Date) {
        v = Moment(v).format('yyyy-MM-DD');
      } else if (v instanceof Array) {
        v = JSON.stringify(v);
      }
      formData.append(key, v);
    }
    crear(formData);
  };

  const showForm = () => {
    publish('Entrega::onReturn');
  };

  return (
    <div>
      <CardContainer height={'100%'}>
        <ModalFirma ref={dialog} title="Generar firma" productor_nombre={cuenta?.productor_nombre} />
        <div className="row">
        <Link 
          className="col-xl-12 pt-4 d-flex justify-content-end align-items-center" 
          to={{ pathname: `/admin/acopio/entregas_por_productor/list` }}>
              <button
                type="button"
                className="btn btn-outline-secondary mt-4"
                style={{ borderRadius: '20px 20px 20px 20px', marginBottom: '-35px' }}>
                <i className="fa fa-arrow-left"></i> Volver al listado
              </button>
            </Link>
          <div className="col-xl-8 col-lg-12">
              <span className="resumen-entrega-titulo">{'Resumen de la entrega'}</span>{' '}
            <div className="pt-3">
              <div className="row">
                <div className="col-xl-12 resumen-entrega-subtitulo">{'Datos de la pesada'}</div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Peso neto'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{formatMoneyNumber(pesoNeto) + 'kgs'}</div>
              </div>
            </div>

            <div className="pt-3">
              <div className="row">
                <div className="col-xl-12 resumen-entrega-subtitulo">{'Pago'}</div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Precio'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{'$ ' + formatMoneyNumber(data.precio_por_kg)}</div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Importe bruto'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{'$ ' + formatMoneyNumber(importeBruto)}</div>
              </div>

              {getIdCatCreditoPrecosecha() > 1 && (
                <>
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Descuentos deudas'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                      {'$ ' + formatMoneyNumber(data.recuperacion_credito || 0)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Monto a pagar'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                      {'$ ' + formatMoneyNumber(importeBruto - (data.recuperacion_credito || 0))}
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Monto pagado'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                  {'$ ' + (isNaN(data.monto_pagado) ? 0 : formatMoneyNumber(data.monto_pagado))}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Pago pendiente'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                  {'$ ' + formatMoneyNumber(importeBruto - (data.recuperacion_credito || 0) - (data.monto_pagado || 0))}
                </div>
              </div>

              {metodoPago && (
                <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Método de pago'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{metodoPago || ''}</div>
                </div>
              )}
            </div>

            {data.firma_image.length === 0 && (
              <>
                <div className="pt-3">
                  <div className="row">
                    <div className="col-xl-12 resumen-entrega-subtitulo pt-4">{'Firma de conformidad'}</div>
                    <div className="col-xl-12 resumen-entrega-firma">
                      <>
                        {imageURL ? (
                          <img
                            src={imageURL}
                            alt="Firma generada"
                            style={{
                              display: 'block',
                              margin: '0 auto',
                              width: '200px',
                              height: '125px',
                            }}
                          />
                        ) : null}
                      </>
                    </div>
                    <p className="resumen-entrega-firmante">{cuenta?.productor_nombre}</p>
                    <br />
                  </div>
                </div>
                <button
                  onClick={() => {
                    dialog.current.show();
                    setImageURL(null);
                  }}
                  type="button"
                  style={{ marginTop: 0 }}
                  className="btn btn-outline-secondary">
                  Generar firma
                </button>
              </>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 resumen-entrega-subtitulo pt-4">
            <button
              disabled={(blocking || !imageURL) ? true : false}
              onClick={() => {
                enviarDatos();
              }}
              type="button"
              style={{ marginTop: 0 }}
              className="btn btn-outline-secondary btn-sirio">
              Guardar
            </button>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorFirma;
