import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';

import NoPagination from '../../components/pagination/noPagination.jsx';

import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import EstimacionesMunicipios from '../../components/dashboards/productores/estimaciones-municipios.js';
import { useCoopConfig, getIdCatCreditoPrecosecha, getUsaEstimacionManual, getUsaSic } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import DebtCalculator from '../../nuup/utils/debtCalculator.js';
import xlsx from 'json-as-xlsx';
import moment from 'moment/moment.js';
import LinkDownload from '../../components/link/link-download.jsx';
import insertIf from '../../utils/insertIf.js';

const AcopioXProductorList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const [producto, setProducto] = useState('café');

  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');

  useEffect(() => {
    let isMounted = true;
    if(!isMounted) return;
    getEtiquetaAnticipo();
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(transformWord(coopConfig.config.cooperativa.producto.nombre, ['singular', 'lowercase']));
    }
    return () => {
      isMounted = false;
    };
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            width: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            width: 80,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            width: 55,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            width: 90,
            Cell: ({ row, value }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: 'ENTREGAS TOTALES',
        columns: [
          {
            Header: 'TOTAL ENTREGAS',
            accessor: 'entregas_no',
            sortable: true,
            minWidth: 50,
            width: 80,
            margin: 0,
            padding: 0,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: 'TIPOS DE CAFÉ ENTREGADOS',
            accessor: 'tipos_cafe',
            sortable: true,
            minWidth: 50,
            show: +coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE,
          },
          {
            Header: 'CATEGORÍA DE ' + transformWord(producto, ['uppercase']),
            accessor: 'tipos_entregas',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarTipo(),
          },
          {
            Header: 'No. RECIPIENTES',
            accessor: 'entregas_no_bultos',
            sortable: true,
            minWidth: 50,
            width: 90,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'entregas_peso_neto_total',
            sortable: true,
            minWidth: 50,
            width: 110,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'ESTIMACIÓN COSECHA',
        columns: [
          {
            Header: `PESO ESTIMADO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'est_peso_estimado_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: '% ENTREGADO VS. ESTIMACIÓN',
            accessor: 'est_entregado_vs_estimacion',
            sortable: true,
            minWidth: 50,
            width: 150,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'PAGOS TOTALES',
        columns: [
          {
            Header: `PRECIO A PRODUCTOR (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'pagos_precio_por_kg',
            sortable: true,
            minWidth: 50,
            width: 180,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            accessor: 'pagos_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'pagos_descuentos_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            accessor: 'pagos_pagado_total',
            sortable: true,
            minWidth: 50,
            width: 165,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `SALDO DE ENTREGAS (${coopConfig.currency()?.symbol})`,
            accessor: 'pagos_saldo_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: transformWord(etiquetaAnticipos, ['plural', 'uppercase']),
        columns: [
          {
            Header: 'Nº ' + transformWord(etiquetaAnticipos, ['plural', 'uppercase']),
            accessor: 'creditos_no',
            sortable: true,
            minWidth: 50,
            width: 85,
            show: getIdCatCreditoPrecosecha() > 1,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: transformWord(etiquetaAnticipos, ['plural', 'uppercase']) + ` PRECOSECHA (${coopConfig.currency()?.symbol})`,
            accessor: 'creditos_total',
            sortable: true,
            minWidth: 50,
            width: 165,
            show: getIdCatCreditoPrecosecha() > 1,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `INTERESES GENERADOS (${coopConfig.currency()?.symbol})`,
            accessor: 'intereses_generados',
            sortable: true,
            minWidth: 50,
            width: 165,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'RECUPERADO',
            accessor: 'creditos_abonado_total',
            sortable: true,
            minWidth: 50,
            width: 90,
            show: getIdCatCreditoPrecosecha() > 1,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO ' + transformWord(etiquetaAnticipos, ['uppercase']) + ` (${coopConfig.currency()?.symbol})`,
            accessor: 'creditos_saldo_total',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 1,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'RECIBOS',
        columns: [
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            width: 80,
            Cell: ({ row }) => {
              if (Number(row.original.entregas_no) > 0 || Number(row.original.creditos_no) > 0) {
                return (
                  <LinkDownload
                    isZip
                    url={`recibos/cuentas/${ciclo.id}/${row.original.productor_uuid}`}
                    label="Descargar"
                    icon={env.images.icon.pdf}
                  />
                );
              } else return 'no hay recibos disponibles por el momento';
            },
          },
        ],
      },
    ],
    [etiquetaAnticipos, ciclo, producto]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 2, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 4, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 5, target: 'localidad', label: 'LOCALIDAD' },
      { value: 6, target: 'region', label: 'REGIÓN' },
      { value: 7, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 8, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 9, target: 'entregas_no', label: 'TOTAL ENTREGAS' },
      { value: 10, target: 'tipos_entregas', label: 'TIPO' },
      { value: 11, target: 'entregas_no_bultos', label: 'Nº RECIPIENTES' },
      { value: 12, target: 'entregas_peso_neto_total', label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion})` },
      { value: 13, target: 'est_peso_estimado_total', label: `PESO ESTIMADO TOTAL (${coopConfig.acopioUnits()?.abreviacion})` },
      { value: 14, target: 'est_entregado_vs_estimacion', label: '% ENTREGADO VS. ESTIMACION' },
      { value: 15, target: 'pagos_precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 16, target: 'pagos_total', label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})` },
      { value: 17, target: 'pagos_descuentos_total', label: 'DESCUENTOS DEUDAS' },
      { value: 18, target: 'pagos_pagado_total', label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})` },
      { value: 19, target: 'pagos_saldo_total', label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})` },
      { value: 20, target: 'creditos_no', label: 'Nº ' + transformWord(etiquetaAnticipos, ['plural', 'uppercase']) },
      {
        value: 21,
        target: 'creditos_total',
        label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ` PRECOSECHA (${coopConfig.currency()?.symbol})`,
      },
      { value: 22, target: 'creditos_abonado_total', label: 'RECUPERADO' },
      {
        value: 23,
        target: 'creditos_saldo_total',
        label: 'SALDO ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ` (${coopConfig.currency()?.symbol})`,
      },
    ],
    [coopConfig]
  );

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);
    Api.Acopio.search('acopio_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        const updatedData = response.data.map(productor => {
          const creditos_total = productor.anticipos.reduce((total, anticipo) => {
            return total + +anticipo.cantidad_anticipo;
          }, 0);
          const creditos_abonado_total = productor.anticipos.reduce((total, anticipo) => {
            return (
              total +
              anticipo.anticipo_pagos.reduce((tot, pago) => {
                return tot + +pago.cantidad_pago;
              }, 0)
            );
          }, 0);

          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            const anticiposProcessed = productor.anticipos.map(anticipo => {
              const debtCalc = new DebtCalculator({
                credito: anticipo,
                interestRate: anticipo.interes ? +anticipo.interes : +productor.interes,
                pagos: anticipo.anticipo_pagos,
                stringDate: null,
              });
              const calc = debtCalc.getDebt();
              return {
                ...anticipo,
                total_saldo: calc.debtToday.pendingCapital / 100,
                total: +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo,
                total_interes: calc.debtToday.totalInterest / 100,
              };
            });

            const creditos_saldo_total = anticiposProcessed.reduce((total, anticipo) => {
              return total + +anticipo.total_saldo;
            }, 0);

            const intereses_generados = anticiposProcessed.reduce((total, anticipo) => {
              return total + +anticipo.total_interes;
            }, 0);

            return {
              ...productor,
              creditos_no: productor.anticipos.length, // número de créditos
              creditos_total: creditos_total / 100, // dinero prestado
              creditos_abonado_total: creditos_abonado_total / 100, // pago de anticipos
              creditos_saldo_total: creditos_saldo_total, //  $item->creditos_total - $item->creditos_abonado_total;
              intereses_generados: intereses_generados, // intereses
            };
          }

          // RETURN DATA WITH NO INTEREST RATE
          return {
            ...productor,
            creditos_no: productor.anticipos.length, // número de créditos
            creditos_total: creditos_total / 100, // dinero prestado
            creditos_abonado_total: creditos_abonado_total / 100, // pago de anticipos
            creditos_saldo_total: (+creditos_total - creditos_abonado_total) / 100, // prestado - abonado
            intereses_generados: 0, // intereses
          };
        });

        setData(updatedData);
        setOriginalData(updatedData);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const exportarXlsx = () => {
    let xlsData1 = [
      {
        sheet: 'Acopios',
        columns: [
          { label: 'CLAVE', value: 'productor_clave_tx' },
          ...insertIf(+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'productor_codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          { label: 'TOTAL ENTREGAS', value: 'entregas_no' },
          ...insertIf(coopConfig.mostrarTipo(), {
            label: 'TIPOS DE ' + transformWord(producto, ['uppercase']) + ' ENTREGADO',
            value: 'tipos_entregas',
          }),
          { label: 'Nº RECIPIENTES', value: 'entregas_no_bultos' },
          { label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion})`, value: 'entregas_peso_neto_total' },
          { label: `PESO ESTIMADO TOTAL (${coopConfig.acopioUnits()?.abreviacion})`, value: 'est_peso_estimado_total', format: '#,#' },
          { label: '% ENTREGADO VS. ESTIMACION', value: row => +row.est_entregado_vs_estimacion / 100, format: '0.00%' },
          { label: 'PRECIO A PRODUCTOR (PERG)', value: 'pagos_precio_por_kg', format: `${coopConfig.currency()?.symbol}#,##0.00` },
          {
            label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            value: row => +row.pagos_total,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          { label: 'DESCUENTOS DEUDAS', value: row => +row.pagos_descuentos_total, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          {
            label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            value: row => +row.pagos_pagado_total,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          {
            label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})`,
            value: 'pagos_saldo_total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          ...insertIf(getIdCatCreditoPrecosecha() > 1, {
            label: 'Nº ' + transformWord(etiquetaAnticipos, ['plural', 'uppercase']),
            value: 'creditos_no',
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 1, {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ` PRECOSECHA (${coopConfig.currency()?.symbol})`,
            value: 'creditos_total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: `INTERESES GENERADOS (${coopConfig.currency()?.symbol})`,
            value: 'intereses_generados',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 1, {
            label: 'RECUPERADO',
            value: 'creditos_abonado_total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 1, {
            label: 'SALDO ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ` (${coopConfig.currency()?.symbol})`,
            value: 'creditos_saldo_total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `AcopiosPorProductor_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Por productor</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}
          acopio
        />
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-12 px-0">
          <CardContainer height={'100%'}>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
              <div className="col-2">
                {ciclo?.uuid && auth.hasPermission('ACOPIOS_X_PRODUCTOR_EXPORTAR') && (
                  <div className="d-flex justify-content-end">
                    <button onClick={() => exportarXlsx()} className={`btn`} type="button">
                      <img src={env.images.icon.xls} className="pr-1" />
                      <p style={{ color: '#789D16' }}>Descargar Excel</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <NoPagination
              blocking={blocking}
              columns={columns}
              data={data}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></NoPagination>
          </CardContainer>
        </div>
      </div>

      <div className="row text-center pt-4">
        <div className="col-md-12">
          <EstimacionesMunicipios idCiclo={ciclo.id} usaSic={getUsaSic() || getUsaEstimacionManual()} producto={producto} />
        </div>
      </div>
    </div>
  );
};

export default AcopioXProductorList;
