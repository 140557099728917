import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import ModalEntrega from '../../components/modal/modal-entrega.jsx';
import { Colors } from '../../constants';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

import { subscribe, unsubscribe } from '../../components/events';
import LinkDownload from '../../components/link/link-download.jsx';
// import toggleIds from '../../utils/toggleIds.js';
import { useHistory } from 'react-router-dom';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import xlsx from 'json-as-xlsx';
import insertIf from '../../utils/insertIf.js';

const EntregasXProductorList = () => {
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [ids, setIds] = useState([]);
  const [folios, setFolios] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const dialog = createRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const [date, setDate] = useState(null);
  const [report, setReport] = useState('all');
  const [blockingReport, setBlockingReport] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [producto, setProducto] = useState('café');
  const menuRef = createRef();

  useEffect(() => {
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(coopConfig.config.cooperativa.producto.nombre);
    }
  }, [coopConfig.config]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ENTREGA',
        columns: [
          {
            Header: '',
            id: 'acopio_uuid',
            accessor: 'x',
            width: 25,
            Cell: ({ row }) => {
              return (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="entregas"
                    type="checkbox"
                    style={{ width: 20, height: 20, accentColor: Colors.primary_600 }}
                    value={row.original.acopio_uuid}
                    onChange={e => {
                      setSelectedData(() => {
                        return e.target.checked ? row.original : {};
                      });
                    }}
                    checked={(selectedData?.acopio_uuid === row.original.acopio_uuid) === true}
                  />
                </div>
              );
            },
          },
          {
            Header: 'FECHA ENTREGA',
            accessor: 'fecha_acopio',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <p className="text-left">{value != null ? value.substr(0, 10) : ''}</p>;
            },
          },
          {
            Header: 'FOLIO ENTREGA',
            accessor: 'folio_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ value, row }) => {
              return (
                <LinkDownload url={`recibos/entregas/descargar/${row.original.acopio_uuid}`} label="Descargar" icon={env.images.icon.pdf} />
              );
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: 'TIPO DE ENTREGA',
        columns: [
          {
            Header: 'TIPO DE CAFÉ',
            accessor: 'tipo_cafe',
            sortable: true,
            minWidth: 50,
            show: +coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE,
          },
          {
            Header: 'PROCESO',
            accessor: 'proceso',
            sortable: true,
            minWidth: 50,
            show: +coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'tipos_entregas',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarTipo(),
          },
          {
            Header: 'COSTALERA',
            accessor: 'nombre_cat_costaleras_estatus',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarCostalera(),
            Cell: ({ value }) => {
              if (value != null) {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {value}
                  </p>
                );
              } else {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {'-'}
                  </p>
                );
              }
            },
          },
          {
            Header: 'LOTE',
            accessor: 'nombre_lote',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarLote(),
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE ENTREGA',
        columns: [
          {
            Header: 'Nº RECIPIENTES',
            accessor: 'no_bultos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'peso_bruto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'peso_tara',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'peso_neto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PAGO',
        columns: [
          {
            Header: `PRECIO A PRODUCTOR (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            accessor: 'importe_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 1,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            accessor: 'pagado_a_productor',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})`,
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'MÉTODO DE PAGO',
            accessor: 'metodo_pago',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'RESULTADOS DE MUESTREO',
        columns: [
          {
            Header: 'MADURACIÓN',
            accessor: 'tipo_maduracion',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_tipo_maduracion,
          },
          {
            Header: 'RENDIMIENTO',
            accessor: 'rendimiento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento,
          },
          {
            Header: 'HUMEDAD',
            accessor: 'humedad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_mancha,
          },
          {
            Header: 'DEFECTO',
            accessor: 'mancha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_humedad,
          },
          {
            Header: 'COLOR',
            accessor: 'color',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_color,
          },
          {
            Header: 'COLOR',
            accessor: 'color_de_cafe',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_color_de_cafe,
          },
          {
            Header: 'AROMA',
            accessor: 'aroma',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_aroma,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'muestreo_observaciones',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_observaciones,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'observaciones',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
    ],
    [producto, selectedData]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'fecha_acopio', label: 'FECHA ENTREF' },
      { value: 2, target: 'estatus', label: 'ESTATUS' },
      { value: 3, target: 'folio_entrega', label: 'FOLIO ENTREGA' },
      { value: 4, target: 'fecha_entrega', label: 'FECHA ENTREGA' },

      { value: 5, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 6, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 7, target: 'folio_acopio', label: 'FOLIO RECIBIDO' },
      { value: 8, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 9, target: 'localidad', label: 'LOCALIDAD' },
      { value: 10, target: 'region', label: 'REGIÓN' },
      { value: 11, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 12, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 13, target: 'no_bultos', label: 'Nº RECIPIENTES' },
      { value: 14, target: 'peso_bruto', label: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion})` },
      { value: 15, target: 'peso_tara', label: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion})` },
      { value: 16, target: 'peso_neto', label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion})` },
      { value: 17, target: 'precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 18, target: 'importe_total', label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})` },
      { value: 19, target: 'total_anticipos_recuperados', label: 'DESCUENTOS DEUDAS' },
      { value: 20, target: 'pagado_a_productor', label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})` },
      { value: 21, target: 'total_saldo', label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})` },
      { value: 22, target: 'metodo_pago', label: 'MÉTODO DE PAGO' },
    ],
    [coopConfig]
  );

  useEffect(() => {
    if (ciclo.id) {
      dialog.current.setIdCiclo(ciclo.id);
    }
  }, [ciclo]);

  useEffect(() => {
    dialog.current.setIdsAcopios(ids.join(','));
  }, [ids]);

  useEffect(() => {
    dialog.current.setFolios(folios);
  }, [folios]);

  useEffect(() => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    setDate(curr.toISOString().substring(0, 10));

    subscribe('Entrega::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Entrega::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const updateData = id_ciclo => {
    setBlocking(true);
    Api.Acopio.search('entregas_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);

        var folios = response.data
          .filter(function(item) {
            return item.folio_entrega != null && item.folio_entrega != '-' && item.folio_entrega != '';
          })
          .map(function(item) {
            return { value: item.folio_entrega, label: item.folio_entrega, date: item.fecha_entrega + ' 12:00:00' };
          })
          .sort(function(a, b) {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            return 0;
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                t => t.value === value.value // && t.name === value.name
              )
          )
          .sort(function(a, b) {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

        setFolios(folios);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    setIds([]);

    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se asignó con éxito el folio ${event.detail.folio} a los acopios seleccionados.`,
    });

    updateData(event.detail.id_ciclo);
  };

  const handleDownloadReport = event => {
    event.preventDefault();
    if (report !== 'all') {
      const filteredDataByDate = data.filter(item => item.fecha_acopio === date);
      exportarXlsx(filteredDataByDate, `entregas_acopios_${ciclo.id}_deFecha_${date}`);
    } else {
      exportarXlsx(data, `entregas_acopios_${ciclo.id}`);
    }
    setMenuVisible(false);
  };

  const eliminarEntrega = () => {
    Swal.fire({
      title: 'El registro de entrega seleccionado se eliminará',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#FF5733',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#f4f4f4',
    }).then(result => {
      if (result.isConfirmed) {
        Api.delete('admin/acopios/entregas', selectedData.acopio_uuid)
          .then(response => {
            if (response.data.success === 1) {
              updateData(ciclo.id);

              Swal.fire({
                icon: 'success',
                title: '',
                text: `Se eliminó el registro seleccionado.`,
              });
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log('error: ', e);
          });
      }
    });
  };

  const exportarXlsx = (data, filename) => {
    let xlsData1 = [
      {
        sheet: 'AcopiosXproductor',
        columns: [
          { label: 'FECHA ENTREGA', value: 'fecha_acopio' },
          { label: 'FOLIO ENTREGA', value: 'folio_acopio' },
          { label: 'CLAVE', value: 'productor_clave_tx' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'productor_codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          { label: 'Nº RECIPIENTES', value: 'no_bultos' },
          { label: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion})`, value: 'peso_bruto' },
          { label: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion})`, value: 'peso_tara' },
          { label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion})`, value: 'peso_neto' },
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_tipo_maduracion, { label: 'MADURACIÓN', value: 'tipo_maduracion' }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento, {
            label: 'RENDIMIENTO',
            value: 'rendimiento',
            format: '0.00%',
          }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_humedad, { label: 'HUMEDAD', value: 'humedad', format: '0.00%' }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_mancha, { label: 'DEFECTO', value: 'mancha', format: '0.00%' }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_color, { label: 'COLOR', value: 'color' }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_aroma, { label: 'AROMA', value: 'aroma' }),
          ...insertIf(!!+coopConfig?.config?.config_muestreo?.mostrar_observaciones, { label: 'OBSERVACIONES', value: 'observaciones' }),
          ...insertIf(coopConfig.mostrarTipo(), {
            label: 'TIPO DE ' + transformWord(producto, ['uppercase']) + ' ENTREGADO',
            value: 'tipos_entregas',
          }),
          ...insertIf(coopConfig.mostrarCostalera(), { label: 'ESTATUS COSTALERA', value: 'nombre_cat_costaleras_estatus' }),
          {
            label: `PRECIO A PRODUCTOR (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`,
            value: row => +row.precio_por_kg,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          {
            label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            value: row => +row.importe_total,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          ...insertIf(getIdCatCreditoPrecosecha() > 1, {
            label: 'DESCUENTOS DEUDAS',
            value: row => +row.total_anticipos_recuperados,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          {
            label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            value: row => +row.pagado_a_productor,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          {
            label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})`,
            value: row => +row.total_saldo,
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          },
          { label: 'MÉTODO DE PAGO', value: 'metodo_pago' },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: filename,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}
          acopio
        />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end" ref={menuRef}>
                <Link to="#" onClick={(e) => {e.preventDefault(); setMenuVisible(true)}} 
                  className="m-r-2" 
                  style={{ color: '#789D16', textDecoration: 'none' }}>
                  <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                </Link>
                {menuVisible && (
                  <div className="modal" 
                    style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)', padding: '16px'}} 
                    onClick={() => setMenuVisible(false)}>
                    <div className="modal-dialog" onClick={e => e.stopPropagation()}
                      style={{position: 'absolute', right: 30, width: '20%', top: 170}}>
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Descargar Reporte</h5>
                          <button type="button" className="close" onClick={() => setMenuVisible(false)}>
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form style={{padding: '16px'}}>
                            <div className="form-check pb-2">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="report"
                                value="all" 
                                checked={report === 'all'}
                                onChange={e => setReport(e.target.value)}
                              />
                              <label className="form-check-label">
                                Todas las entregas
                              </label>
                            </div>
                            <div className="form-check pb-2">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="report" 
                                value="date"
                                checked={report !== 'all'}
                                onChange={e => setReport(e.target.value)}
                              />
                              <label className="form-check-label">
                                Entregas por fecha
                              </label>
                            </div>
                            {report === 'date' && (
                              <div className="form-group">
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  value={date}
                                  onChange={e => setDate(e.target.value)}
                                />
                              </div>
                            )}
                            {blockingReport && (
                              <div className="d-flex justify-content-start float-right">
                                <div
                                  className="spinner-border text-warning mr-2"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ height: 24, width: 24 }}></div>
                                <span className="mt-1">Descargando ...</span>
                              </div>
                            )}
                            {!blockingReport && (
                              <button
                                type="submit"
                                className="btn btn-xs btn-outline-secondary btn-sirio float-right"
                                onClick={e => handleDownloadReport(e)}>
                                Descargar
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <NoPagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></NoPagination>

        <div className="row mt-3">
          <div className="col-md-12">
            {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
              <Link
                style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                className="btn btn-outline-secondary btn-sirio"
                to="/admin/acopio/entregas_por_productor/seleccion">
                <i className="fa fa-plus"></i> Nueva entrega
              </Link>
            )}

            {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
              <>
                <button
                  onClick={() =>
                    history.push(
                      `/admin/acopio/entregas_por_productor/pago_pendiente/${selectedData.productor_id}/${selectedData.productor_uuid}/${selectedData.id_acopio}`
                    )
                  }
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}
                  disabled={!selectedData}>
                  <i className="fa fa-plus"></i> Pago pendiente
                </button>
                <button
                  onClick={() =>
                    history.push(
                      `/admin/acopio/entregas_por_productor/form/${selectedData.productor_id}/${selectedData.productor_uuid}/${selectedData.acopio_uuid}`
                    )
                  }
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}
                  disabled={!selectedData}>
                  <i className="fa fa-pen"></i> Editar entrega
                </button>

                <button
                  onClick={() => eliminarEntrega()}
                  type="button"
                  className="btn"
                  style={{ borderColor: 'red', borderRadius: '20px 20px 20px 20px', marginLeft: 10 }}
                  disabled={!selectedData}>
                  <span style={{ color: 'red' }}>
                    <i className="fa fa-trash"></i> {' Eliminar entrega'}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>

        <ModalEntrega ref={dialog} title="Actualizar estado"></ModalEntrega>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorList;
